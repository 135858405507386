<template>
  <v-container
    fluid
    class="pa-0 ma-0 white-wavy-wallpaper"
    style="height: 100%; width: 100%; overflow-y: auto;"
  >
    <app-bar class="white-wavy-wallpaper" :drawer="drawer" :admin="false" @toggleSideBar="toggle">
      <bz-support class="mt-12" slot="right" email="usersupport@beezsocialerp.com"></bz-support>
    </app-bar>
    <side-bar
      :jobTitle="jobTitle"
      :avatar="avatar"
      :items="items"
      :drawer="drawer"
      :editProfile="hasProfile"
      :exactLink="exactLink"
      :highlightTitle="highlightTitle"
      @logoutEvent="logoutDialog = true"
    />

    <div class="d-flex">
      <v-container class="pl-12 ml-12 pt-12">
        <admin-header v-if="!skipBreadcrumb" :title="headerTitle" :links="links"></admin-header>
        <router-view />
      </v-container>      
    </div>

    <v-dialog v-model="logoutDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="subtitle-1">Are you sure you want to leave?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="buttons" :loading="logoutLoading" text @click="onLogout">Yes</v-btn>
          <v-btn color="buttons" text @click="logoutDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="end" class="mr-12 mb-12">
      <!-- <bz-support email="usersupport@beezsocialerp.com"></bz-support> -->
    </v-row>
  </v-container>
</template>

<script>
import BzSupport from "@shared/components/BzSupport";
import AppBar from "@/beez-admin/components/AppBar";
import SideBar from "@/beez-admin/components/SideBar";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
export default {
  name: "UserHome",
  components: {
    AppBar,
    SideBar,
    BzSupport,
    AdminHeader
  },
  data() {
    return {
      userCardData: {
        profilePictureId: "",
        media: ""
      },
      highlightTitle: "",
      exactLink: true,
      userImage: null,
      hasProfile: false,
      jobTitle: "",
      avatar: "",
      logoutLoading: false,
      drawer: false,
      headerTitle: "",
      skipBreadcrumb: false,
      links: [],
      logoutDialog: false,
      dialog: true,
      items: [],
      accountModules: null,
      cardData: null
    };
  },
  computed: {
    ...mapFields("auth", ["currentUser"]),
    ...mapFields("user", ["introductionCardUser", "finishOnBoarding"]),
    ...mapFields("welcome", ["elevationWelcomeIsSeen"])
  },
  methods: {
    ...mapActions("auth", {
      logoutUser: "logoutUser"
    }),
    ...mapActions("user", {
      getUserCard: "getUserCard"
    }),
    ...mapActions("introduction", {
      getIntroductionCard: "getIntroductionCard"
    }),

    ...mapActions("admin", {
      fetchFilesUploaded: "fetchFilesUploaded"
    }),
    toggle() {
      this.drawer = !this.drawer;
    },

    async onLogout() {
      this.logoutLoading = true;
      try {
        await this.logoutUser();
        this.logoutLoading = false;
      } finally {
        this.$router.replace({
          name: "log-in"
        });
      }
    },
    async fetchImage(profilePictureId) {
      try {
        const userImage = await this.fetchFilesUploaded({
          logoId: profilePictureId
        });
        if (userImage) {
          this.avatar = userImage.url;
        }
      } catch (error) {}
    },
    highLightTitle(value) {
      if ("skip" in value.meta.breadcrumbs && value.meta.breadcrumbs.skip) {
        this.skipBreadcrumb = true;
      } else {
        this.skipBreadcrumb = false;
        this.links = value.meta.breadcrumbs;
        if (this.links && this.links.length > 0) {
          this.headerTitle = this.links[this.links.length - 1].text;
        } else {
          this.headerTitle = "";
        }
      }

      const path = value.path.split("/");
      if (path.length > 3) {
        this.exactLink = false;
        switch (path[2]) {
          case "elevation-module":
            this.highlightTitle = this.items[1].title;
            break;
        }
      } else {
        this.exactLink = true;
      }
    },
    changeProfileStatus() {
      this.hasProfile = true;
      this.items = [
        {
          title: "BeeZ-In",
          icon: "mdi-bee-flower",
          link: "beez-in",
          type: "beez-in"
        },
        {
          title: "Elevation",
          icon: "mdi-elevation-rise",
          link: "user-training",
          type: "item"
        }
      ];
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(value) {
        this.highLightTitle(value);
      }
    },
    drawer: {
      handler(value) {}
    },
    introductionCardUser: {
      deep: true,
      async handler(value) {
        this.jobTitle = value[0].position;
        await this.fetchImage(value[0].profilePictureId);
      }
    },
    finishOnBoarding: {
      handler(value) {
        if (value) {
          this.changeProfileStatus();
          if (this.currentUser.roles.length == 1) {
            if (this.items[this.items.length - 1].title == "Elevation") {
              this.items.pop();
            }
          }
        }
      }
    },
    elevationWelcomeIsSeen: {
      handler() {
        this.changeProfileStatus();
      }
    }
  },
  async created() {
    const accountId = this.currentUser.roles[0].accountId;
    this.accountModules = await this.getUserCard();
    this.cardData = await this.getIntroductionCard({
      accountId: accountId
    });
    // console.log(this.accountModules);
    if (this.accountModules[this.accountModules.length - 1]) {
      this.jobTitle = this.accountModules[
        this.accountModules.length - 1
      ].position;
      this.userCardData.profilePictureId = this.accountModules[
        this.accountModules.length - 1
      ].profilePictureId;
    }

    if (this.userCardData.profilePictureId) {
      this.changeProfileStatus();
      this.highLightTitle(this.$route);
      await this.fetchImage(this.userCardData.profilePictureId);
    }

    // if (this.currentUser.roles.length > 1){
    //   if(this.currentUser.roles[1].resourceGroups[0].name == ){

    //   }
    // }

    if (this.currentUser.roles.length == 1) {
      if (this.items[this.items.length - 1].title == "Elevation") {
        this.items.pop();
      }
    }
  }
};
</script>

<style scoped></style>
